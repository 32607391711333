import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
    HttpHeaders
  } from '@angular/common/http';
  import { Observable, throwError } from 'rxjs';
  import { map, catchError } from 'rxjs/operators';
  import { Injectable } from '@angular/core';
  import { MainService } from './main.service';
  import { from } from 'rxjs';


  import { Plugins } from '@capacitor/core';

const { Geolocation } = Plugins;
const { Storage } = Plugins;



  @Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  
  constructor(public mainService: MainService) { }

  async getToken(){
    const ret = await Storage.get({ key: 'token' });
    const token = JSON.parse(ret.value);
    return token;
  }

  async getType() {
    const initialValue = await Storage.get({ key: 'isFile' });
    const type = JSON.parse(initialValue.value);
    return type;
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(req, next));
}

private async handleAccess(req: HttpRequest<any>, next: HttpHandler):
    Promise<HttpEvent<any>> {
    const token = await this.getToken();
    const type = await this.getType();
    if(type == 'NO'){
    const changedReq = req.clone({
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        })
    });
    console.log("JSON");
    return next.handle(changedReq).toPromise();
  }else{
    const changedReq = req.clone({
      headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token,
          'Access-Control-Allow-Origin': '*'
      })
  });
  console.log("FILE");
    return next.handle(changedReq).toPromise();
  }
}

//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const currentAuthToken = this.getToken();

//     console.log('/////// Token ' + currentAuthToken);

//     if (currentAuthToken && currentAuthToken) {
//         const headers = {
//             'Authorization': 'Bearer ' + currentAuthToken,
//         };
//         if (request.responseType === 'json') {
//             headers['Content-Type'] = 'application/json';
//         }
//         request = request.clone({
//             setHeaders: headers
//         });
//     }
//     console.log("Interceptor " + currentAuthToken);
//     return next.handle(request);
// }


}