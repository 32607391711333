import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';
import { Router } from '@angular/router';
import { MainService } from './main.service';
import { AlertController, NavController, LoadingController, ToastController } from '@ionic/angular';
 
const { PushNotifications, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FCMService {

  constructor(
    private router: Router,
    public mainService: MainService,
    private alertCtrl: AlertController
    ) { }

  async initPush() {
    const initialValue = await Storage.get({ key: 'token' });
    const token = JSON.parse(initialValue.value);
    let test = token != null && token != undefined && token != "";
    console.log("Test " + test)
    if (Capacitor.platform !== 'web' && test == true)  {
      this.registerPush();
      console.log("Test Passed");
    }
  }
 
  private registerPush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });
 
    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        console.log('My token: ' + JSON.stringify(token));
        console.log('My token value: ' + JSON.stringify(token.value));

        let dets = {
          token: token.value,
        };
        let url = 'auth/notifications';
        this.mainService.createData(dets, url).subscribe((result) => {
          console.log(result);
        }, (err) => {
          console.log(err);
        });
      }
    );
 
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });
 
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );
 
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: PushNotificationActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (data.detailsId) {
          this.router.navigateByUrl(`/home/${data.detailsId}`);
          console.log("ROuting this shit biznich");
        }
      }
    );
  }
}
