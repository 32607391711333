import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AlertController, NavController, LoadingController, ToastController } from '@ionic/angular';

import { MainService } from './main.service';
import { FCMService } from './fcm.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/tabs',
      icon: 'home'
    },
        {
      title: 'Calendar',
      url: '/tabs/calendar',
      icon: 'calendar'
    },
        // {
    //   title: 'Accounting',
    //   url: '/tabs/accounting',
    //   icon: 'cash'
    // },
    // {
    //   title: 'Files',
    //   url: '/album',
    //   icon: 'folder'
    // },
    // {
    //   title: 'Fleet',
    //   url: '/album',
    //   icon: 'car'
    // },
    // {
    //   title: 'Bids',
    //   url: '/album',
    //   icon: 'documents'
    // },
    // {
    //   title: 'Inventory',
    //   url: '/album',
    //   icon: 'clipboard'
    // },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'lock-closed'
    },
  ];
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public mainService: MainService,
    private alertCtrl: AlertController,
    public toastController: ToastController,
    private fcmService: FCMService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  // async foregroundToast(title, body) {
  //   const toast = await this.toastController.create({
  //     message: title + " - " + body,
  //     duration: 2000
  //   });
  //   toast.present();
  // }
  
}
