import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import jwt_decode from "jwt-decode";

import { ToastController } from '@ionic/angular';

import { CallNumber } from '@ionic-native/call-number/ngx';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';

import { Plugins} from '@capacitor/core';


const { Storage, Geolocation } = Plugins;



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

// const url = "http://localhost:8080/api/";
// const url = "https://kenectwork.com/api/";
const url = "https://kenectin.com/api/";


@Injectable({
  providedIn: 'root'
})
export class MainService {
  public token: any;
  public key: any;
  public data: any;
  public company: any;
  public currentUser: any;
  public item: any;
  user: any;
  public id: any;
  headers: any;
  decoded:any;

  constructor(public hp: HttpClient, public callNumber: CallNumber, private launchNavigator: LaunchNavigator, public toastController: ToastController) { }

  // Reuseable function for serving data to get requests within component.
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  async getTokenId() {
    const ret = await Storage.get({ key: 'token' });
    const token = JSON.parse(ret.value);
    return token;
  }

  async locationConfig(){
    const initialValue = await Storage.get({ key: 'email'});
    const email = JSON.parse(initialValue.value);

    const wait = Geolocation.watchPosition({}, (position, err) => {
      let dets = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        cred: email
      };
      this.updateLocation(dets).subscribe((data) => {
      });
    })
  }

  async logout() {
    await Storage.set({
      key: 'token',
      value: ''
    });
  }

  getData(link): Observable<any> {

    this.locationConfig();
    this.setStorage('isFile', "NO");
    return this.hp.get(url + link).pipe(
      map(this.extractData));
  }

  createData(passed, link): Observable<any> {
    this.locationConfig();
    this.setStorage('isFile', "NO");
    let converted = JSON.stringify(passed);
    return this.hp.post<any>(url + link, converted, httpOptions).pipe(
      tap((response) => console.log(`customer created in w/ id=${response}`))
    );
  }

  editData(passed, link): Observable<any> {
    this.locationConfig();
    this.setStorage('isFile', "NO");
    let converted = JSON.stringify(passed);
    return this.hp.put<any>(url + link, converted, httpOptions).pipe(
      tap((response) => console.log(`customer created in w/ id=${response}`))
    );
  }

  deleteData(link): Observable<any> {
    this.locationConfig();
    this.setStorage('isFile', "NO");
    return this.hp.delete<any>(url + link, httpOptions).pipe(
    );
  }

 updateLocation(dets): Observable<any> {
    let detail = JSON.stringify(dets);
    this.setStorage('isFile', "NO");
    return this.hp.put<any>(url + 'auth/location', detail, httpOptions).pipe(
      tap((detail) => console.log(`customer created in w/ id=${detail}`))
    );
  }

  async navigateLocation(address) {
    const position = await Geolocation.getCurrentPosition();
    let lat = position.coords.latitude;
    let long = position.coords.longitude;
      console.log("Navigation Selected")
      let options: LaunchNavigatorOptions = {
        app: this.launchNavigator.APP.USER_SELECT,
        start: [lat, long]
      };
      this.launchNavigator.navigate(address, options)
        .then(success => {
          console.log(success);
        }, error => {
          console.log(error);
        })
  }

  callCustomer(number) {
    this.callNumber.callNumber("+1" + number, true)
      .then(() => console.log('Dialer Launched!'))
      .catch(() => console.log('Error launching dialer'));
  }

  removeID(passed, link, token): Observable<any> {
    let hOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    let converted = JSON.stringify(passed);
    return this.hp.post<any>(url + link, converted, hOptions).pipe(
      tap((response) => console.log(`customer created in w/ id=${response}`))
    );
  }

    // Check Token Validity

    getTokenExpirationDate(token: string): Date {
      this.decoded = jwt_decode(token);
  
      if (this.decoded.exp === undefined) return null;
  
      const date = new Date(0); 
      date.setUTCSeconds(this.decoded.exp);
      return date;
    }
  
    isTokenExpired(token) {
      if(!token) token = this.getToken();
      if(!token) return true;
  
      const date = this.getTokenExpirationDate(token);
      if(date === undefined) return false;
      return !(date.valueOf() > new Date().valueOf());
    }
  
    async getToken() {
      const initialValue = await Storage.get({ key: 'token' });
      const token = JSON.parse(initialValue.value);
      return token;
    }

    async setStorage(keyName, valueName){
      await Storage.set({
        key: keyName,
        value: JSON.stringify(valueName)
      });
  
    }

    uploadImage(blob, filename, link): Observable<any> {
      this.setStorage('isFile', "YES");
      const formData = new FormData();
      formData.append('file', blob, filename);
      return this.hp.post<any>(url + link, formData).pipe(
        tap((response) => console.log(`customer created in w/ id=${response}`))
      );
    }

  

}
